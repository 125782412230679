<div class="w-full h-full relative" (window:resize)="onResize()">
    <p-confirmDialog></p-confirmDialog>
    <div id="bottom-left-buttons-container" class="absolute bottom-0 left-0 z-5 pl-3 pb-3">
        <div class="flex flex-row gap-2">
            <p-button
                label="Save"
                icon="pi pi-check"
                size="small"
                (onClick)="save()"
            />
            <p-button
                label="Export"
                icon="pi pi-download"
                size="small"
                (onClick)="export()"
            />

            <p-fileUpload 
                mode="basic" 
                chooseLabel="Import" 
                chooseIcon="pi pi-upload"
                accept=".xml"
                styleClass="p-button-sm"
                (onSelect)="import($event)" />
        </div>   
    </div>
    <div id="bottom-left-buttons-container" class="absolute top-0 right-0 z-5 pr-3 pt-3">
        <p-button
            (onClick)="showShortCut()"
            size="small"
            pTooltip="View Shortcuts"
            [showDelay]="1000" 
            [hideDelay]="300"
        >
            <fa-icon [icon]="['fas', 'keyboard']" size="xl"></fa-icon>
        </p-button>
    </div>
    <p-dialog header="Keyboard Shortcuts" [modal]="true" [(visible)]="showShortcut" [style]="{ width: '45rem', height: '50rem' }">
        <table class="h-full">
            <tbody class="text-xl">
                <tr>
                    <td>Undo</td>
                    <td class="pl-3"><code>ctrl + Z</code></td>
                </tr>
                <tr>
                    <td>Redo</td>
                    <td class="pl-3"><code>ctrl + ⇧ + Z</code></td>
                </tr>
                <tr>
                    <td>Select All</td>
                    <td class="pl-3"><code>ctrl + A</code></td>
                </tr>
                <tr>
                    <td>Scrolling (Vertical)</td>
                    <td class="pl-3"><code>ctrl + Scrolling</code></td>
                </tr>
                <tr>
                    <td>Scrolling (Horizontal)</td>
                    <td class="pl-3"><code>⇧ + Scrolling</code></td>
                </tr>
                <tr>
                    <td>Direct Editing</td>
                    <td class="pl-3"><code>E</code></td>
                </tr>
                <tr>
                    <td>Hand Tool</td>
                    <td class="pl-3"><code>H</code></td>
                </tr>
                <tr>
                    <td>Lasso Tool</td>
                    <td class="pl-3"><code>L</code></td>
                </tr>
                <tr>
                    <td>Space Tool</td>
                    <td class="pl-3"><code>S</code></td>
                </tr>
                <tr>
                    <td>Replace Tool</td>
                    <td class="pl-3">R</td>
                </tr>
                <tr>
                    <td>Append anything</td>
                    <td class="pl-3">A</td>
                </tr>
                <tr>
                    <td>Create anything</td>
                    <td class="pl-3">N</td>
                </tr>
            </tbody>
        </table>
    </p-dialog>

    <div id="zoom-controls-container" class="absolute right-0 bottom-0 z-5 pr-3 pb-7">
        <div class="flex flex-column gap-1">
            <p-button
                icon="pi pi-expand"
                [rounded]="true"
                severity="secondary"
                (onClick)="zoomReset()"
                pTooltip="Reset zoom" 
                [showDelay]="1000" 
                [hideDelay]="300"
            />
            <p-button
                icon="pi pi-search-plus"
                [rounded]="true"
                severity="secondary"
                (onClick)="zoomIn()"
                pTooltip="Zoom in" 
                [showDelay]="1000" 
                [hideDelay]="300"
            />
            <p-button 
                icon="pi pi-search-minus"
                [rounded]="true"
                severity="secondary"
                (onClick)="zoomOut()"
                pTooltip="Zoom out" 
                [showDelay]="1000" 
                [hideDelay]="300"
            />
        </div> 
    </div>
    <div class="flex flex-row h-full w-full">
        <div #canvas id="canvas" style="width: 100%; height: 100%; border: 1px solid #ccc;">
        
        </div>
    </div>
</div>
